import React from "react";
import { Navbar } from "./Navbar";
import { Footer } from "./Footer";

export const ChiSiamo = () => {
  return (
    <div className="flex flex-col items-center bg-white w-full h-full">
      <Navbar />

      {/* Immagine sotto la Navbar */}
      <div className="w-full border-[30px] border-white">
        <img
          src="../img/Sfondovecchioorologi.png"
          alt="Sfondo Chi Siamo"
          className="w-full object-cover h-[300px]"
        />
      </div>

      {/* Sezione contenuto */}
      <div className="flex flex-col items-center w-full px-[10%] py-[50px]">
        <h1 className="text-3xl font-bold mb-[20px] font-elegant">Chi Siamo</h1>
        <p className="text-lg text-gray-700 text-center leading-8 font-elegant text-2xl">
          L’oreficeria Strippoli di Via Lecco nasce nell’immediato dopoguerra
          dalla volontà e dall’esperienza del Sig. Vincenzo. La passione
          dell’orologeria viene poi trasmessa al figlio Aldo che prosegue
          nell’attività familiare e comincia a espandersi anche nel settore
          dell’oreficeria.
        </p>

        {/* Sezione Mission e Vision */}
        <div className="flex flex-row justify-center gap-[50px] mt-[40px]">
          {/* Puoi aggiungere contenuti opzionali per mission e vision qui */}
        </div>
      </div>

      <Footer />
    </div>
  );
};

import React from "react";

export const Footer = () => {
  return (
    <footer className="flex flex-col items-center bg-gray-300 mt-[104px] w-full">
      <div className="flex flex-col md:flex-row items-center justify-between gap-[30px] mt-[67px] w-[1127px]">
        <div className="text-center md:text-left">
          <p className="text-lg font-elegant text-2xl">Indirizzo: Via Lecco, 18, 20900 Monza MB</p>
          <p className="text-lg font-elegant text-2xl">Telefono: +39 039 388876</p>
        </div>
        <div className="text-center">
          <p className="text-lg font-elegant text-2xl">Email: orologi.strippoli@gmail.com</p>
        </div>
      </div>
      <div className="flex items-center justify-center bg-red-600 text-white w-full h-[121px] mt-[63px]">
        <p className="text-lg text-center font-elegant text-2xl">Strippoli Maurizio & C. Sas</p>
        <p className="text-lg text-center font-elegant text-2xl">P.IVA – 02350930968</p>

      </div>
    </footer>
  );
};

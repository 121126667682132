import React, { useEffect, useState } from "react";
import axios from "axios";
import { Navbar } from "./Navbar";
import { Footer } from "./Footer";
import { useNavigate } from "react-router-dom";

interface Product {
  id: string;
  category: string;
  name: string;
  material: string;
  price: string;
  description: string;
  image: string;
  marca: string;
}

export const Gioielleria = () => {
  const [products, setProducts] = useState<Product[]>([]);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const navigate = useNavigate();

  const SPREADSHEET_ID = "1P6aNYWLOmysvMxCGomJX3sHD630bIEIbRdrVK0kj42g";
  const YOUR_API_KEY = "AIzaSyDApd2IAji0C8D7cTpv8phMYiqDShinc5c";
  const SHEET_NAME = "Strippoli";

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        setErrorMessage(null);
        console.log("Caricamento prodotti Gioielleria...");

        const url = `https://sheets.googleapis.com/v4/spreadsheets/${SPREADSHEET_ID}/values/${SHEET_NAME}?key=${YOUR_API_KEY}`;
        console.log("URL della richiesta:", url);

        const response = await axios.get(url);
        const rows = response.data.values;

        console.log("Dati ricevuti:", rows);

        if (!rows || rows.length <= 1) {
          throw new Error("Nessun dato trovato nel foglio.");
        }

        const loadedProducts = rows.slice(1).map((row: string[]) => {
          const convertedImage = row[6]
            ?.replace("/file/d/", "/uc?export=view&id=")
            .replace("/view?usp=drive_link", "");
          console.log("Converted Image URL:", convertedImage);

          return {
            id: row[0],
            category: row[1],
            name: row[2],
            material: row[3],
            price: row[4],
            description: row[5],
            image: convertedImage || "",
            marca: row[7],
          };
        });

        // Filtra solo i prodotti della categoria Gioielleria
        const gioielleriaProducts = loadedProducts.filter(
          (product: { category: string }) =>
            product.category.toLowerCase() === "gioielleria"
        );

        setProducts(gioielleriaProducts);
        console.log("Prodotti Gioielleria:", gioielleriaProducts);
      } catch (err: any) {
        let errorDetail = "Errore sconosciuto.";
        if (err.response) {
          errorDetail = `Errore API: ${err.response.data.error.message}`;
        } else if (err.request) {
          errorDetail = "Impossibile connettersi all'API di Google Sheets.";
        } else {
          errorDetail = err.message || "Errore imprevisto.";
        }
        setErrorMessage(errorDetail);
        console.error("Errore durante il caricamento:", errorDetail);
      }
    };

    fetchProducts();
  }, []);

  return (
    <>
      <Navbar />
      <div className="flex flex-col items-center bg-white w-full font-elegant text-2xl border-[30px] border-white">
        <img
          src="../img/Missitaliabanner.png"
          alt="Gioielleria Rectangle"
          className="w-full h-[264px] object-cover"
        />

        {/* Titolo della sezione */}
        <h1 className="text-2xl font-bold mt-6 font-elegant">Gioielleria</h1>
        {errorMessage ? (
          <p className="text-red-500 text-center mt-6">{errorMessage}</p>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-8 mt-12 px-6 border-1 border-green-800">
            {products.map((product) => (
              <div
                key={product.id}
                className="flex flex-col items-center border border-green-800 rounded-lg p-4 shadow-md bg-white font-elegant text-2xl cursor-pointer border-1 border-green-800"
                onClick={() =>
                  navigate("/prodotti-gioielleria", { state: product })
                }
              >
                <img
                  src={product.image}
                  alt={product.name}
                  onError={(e) => {
                    console.error(
                      `Errore nel caricamento immagine: ${product.image}`
                    );
                  }}
                  className="h-[200px] w-full object-cover rounded-lg font-elegant text-2xl"
                />
                <h3 className="text-lg font-semibold mt-4 text-center font-elegant text-2xl">
                  {product.name}
                </h3>
                <h3 className="text-lg font-semibold mt-4 text-center font-elegant text-2xl">
                  {product.marca}
                </h3>
                <p className="text-sm text-gray-700 mt-2 text-center font-elegant text-2xl">
                  {product.material}
                </p>
                <p className="text-lg font-bold mt-2 text-center font-elegant text-2xl">
                  {product.price}
                </p>
              </div>
            ))}
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

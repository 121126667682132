import React from "react";
import { Navbar } from "./Navbar";
import { Footer } from "./Footer";
import { useNavigate } from "react-router-dom";

interface Brand {
  id: string;
  name: string;
  image: string;
}

const brands: Brand[] = [
  {
    id: "1",
    name: "Miluna",
    image: "/img/Milunaimg2.png",
  },
  {
    id: "2",
    name: "Lorenz",
    image: "/img/Lorenzimg2.png",
  },
  {
    id: "3",
    name: "Citizen",
    image: "/img/Screenshot 2024-11-29 154151.png",
  },
  {
    id: "4",
    name: "Vagary",
    image: "/img/Screenshot 2024-11-29 154928.png",
  },
  {
    id: "5",
    name: "Altre Marche",
    image: "/img/Screenshot 2024-11-29 154813.png",
  },
];

export const Marchi: React.FC = () => {
  const navigate = useNavigate();

  return (
    <>
    <div className="h-full">
      <Navbar />
      <div className="flex flex-col items-center bg-white w-full h-full py-12 px-6 font-elegant">
        <h2 className="text-3xl font-bold mb-6">I Nostri Marchi</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 w-full">
          {brands.map((brand) => (
            <div
              key={brand.id}
              className="flex flex-col items-center border rounded-lg shadow-md bg-white overflow-hidden cursor-pointer hover:shadow-lg transition-shadow border-1 border-green-800"
              onClick={() =>
                navigate("/lista-marchio", { state: { marca: brand.name } })
              }
            >
              <img
                src={brand.image}
                alt={brand.name}
                className="w-full h-[200px] object-cover border-[7px] border-white  border rounded-lg"
              />
              <h3 className="text-xl font-medium text-center mt-4 px-4">{brand.name}</h3>
            </div>
          ))}
        </div>
      </div>
      <Footer />
      </div>
    </>
  );
};

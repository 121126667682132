import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Navbar } from "./Navbar";
import { Footer } from "./Footer";

interface Product {
  id: string;
  category: string;
  name: string;
  material: string;
  price: string;
  description: string;
  image: string;
  marca: string;
}

export const HomePage = () => {
  const [products, setProducts] = useState<Product[]>([]);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const navigate = useNavigate();

  const SPREADSHEET_ID = "1P6aNYWLOmysvMxCGomJX3sHD630bIEIbRdrVK0kj42g";
  const YOUR_API_KEY = "AIzaSyDApd2IAji0C8D7cTpv8phMYiqDShinc5c";
  const SHEET_NAME = "Strippoli"; // Nome del foglio

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        setErrorMessage(null);
        console.log("Inizio caricamento prodotti da Google Sheets...");

        const url = `https://sheets.googleapis.com/v4/spreadsheets/${SPREADSHEET_ID}/values/${SHEET_NAME}?key=${YOUR_API_KEY}`;
        console.log("URL della richiesta:", url);

        const response = await axios.get(url);
        const rows = response.data.values;

        console.log("Dati ricevuti:", rows);

        if (!rows || rows.length <= 1) {
          throw new Error("Nessun dato trovato nel foglio.");
        }

        const loadedProducts = rows.slice(1).map((row: string[]) => ({
          id: row[0],
          category: row[1],
          name: row[2],
          material: row[3],
          price: row[4],
          description: row[5],
          image: row[6]?.replace("/view?usp=drive_link", "/preview"),
          marca: row[7],
        }));

        setProducts(loadedProducts);
        console.log("Prodotti caricati:", loadedProducts);
      } catch (err: any) {
        let errorDetail = "Errore sconosciuto.";
        if (err.response) {
          errorDetail = `Errore API: ${err.response.data.error.message}`;
        } else if (err.request) {
          errorDetail = "Impossibile connettersi all'API di Google Sheets.";
        } else {
          errorDetail = err.message || "Errore imprevisto.";
        }
        setErrorMessage(errorDetail);
        console.error("Errore durante il recupero dei dati:", errorDetail);
      }
    };

    fetchProducts();
  }, []);

  return (
    <>
      <Navbar />

      <div className="flex flex-col items-center bg-white w-full font-elegant text-2xl">
        {/* Immagine iniziale */}
        <img
          className="mt-12 h-[396px] w-[110vh] object-cover"
          src="/img/Sfondoiniziale.png"
          alt="Sfondoiniziale"
        />

        {/* Sezione Gioielleria e Orologeria */}
        <div className="flex justify-center items-center gap-6 w-full mt-12 px-12">
          <div className="flex flex-col items-center">
            <a href="/gioielleria">
              <img
                className="h-[565px] w-auto object-cover rounded-lg"
                src="/img/Milunaimg2.png"
                alt="Gioielleria"
              />
            </a>
            <a
              href="/gioielleria"
              className="text-xl font-medium text-black mt-4"
            >
              GIOIELLERIA
            </a>
          </div>
          <div className="flex flex-col items-center">
            <a href="/orologeria">
              <img
                className="h-[565px] w-auto object-cover rounded-lg"
                src="/img/Lorenzimg2.png"
                alt="Orologeria"
              />
            </a>
            <a
              href="/orologeria"
              className="text-xl font-medium text-black mt-4"
            >
              OROLOGERIA
            </a>
          </div>
        </div>

        {/* Laboratorio */}
        <div className="flex flex-col items-center w-full mt-12 px-6">
          
            <img
              className="h-[550px] w-auto object-cover rounded-lg"
              src="/img/rectangle-5-2@2x.png"
              alt="Laboratorio"
            />
          
          <h1 className="text-2xl font-medium text-black mt-4">
            Laboratorio di Orologeria
          </h1>
          <p className="text-lg text-gray-600 text-center mt-4 px-12">
            Oggi l’orologeria/oreficeria Strippoli è una realtà conosciuta e ben
            radicata nel territorio monzese ed è guidata da Maurizio e Laura,
            nipoti del fondatore e animati dallo stesso spirito. Maurizio ha
            affinato le proprie capacità tecniche nella scuola di orologeria
            Capac di Milano, mentre Laura, addetta alle vendite ha la capacità
            innata di mettere a proprio agio il cliente e indirizzandolo nella
            scelta giusta.
          </p>
        </div>

        {/* Prodotti a scorrimento */}
        <div className="w-full mt-12 px-6">
  <h2 className="text-2xl font-medium text-black text-center mb-6">
    Scopri i nostri prodotti
  </h2>
  {errorMessage ? (
    <p className="text-red-500 text-center">{errorMessage}</p>
  ) : (
    <div
      className="overflow-x-auto flex gap-6 scrollbar-thin scrollbar-thumb-red-500 scrollbar-track-gray-100 scrollbar-thumb-rounded-full"
    >
      {products.map((product) => (
        <div
          key={product.id}
          className="min-w-[250px] flex flex-col items-center border border-green-800 rounded-lg p-4 shadow-md bg-white cursor-pointer text-center"
          onClick={() => navigate("/prodotti-orologeria", { state: product })}
        >
          <img
            className="h-[200px] w-auto object-cover rounded-lg text-center"
            src={product.image}
            alt={product.name}
          />
          <p className="text-lg text-black mt-4">{product.name}</p>
          <p className="text-sm text-gray-600">{product.material}</p>
          <p className="text-lg font-medium text-gray-800">
            {product.price}
          </p>
        </div>
      ))}
    </div>
  )}
</div>

      </div>

      <Footer />
    </>
  );
};

import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { HomePage } from "./components/HomePage";
import { Gioielleria } from "./components/Gioielleria";
import { Orologeria } from "./components/Orologeria";
import { ProdottiGioielleria } from "./components/ProdottiGioielleria";
import { ProdottiOrologeria } from "./components/ProdottiOrologeria";
import { ChiSiamo } from "./components/ChiSiamo";
import { Contattaci } from "./components/Contattaci";
import { Marchi } from "./components/Marchi";
import { ListaOggettiMarchio } from "./components/ListaOggettiMarchio";
import { ProdottiMarchi } from "./components/ProdottiMarchi";



const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/gioielleria" element={<Gioielleria />} />
        <Route path="/orologeria" element={<Orologeria />} />
        <Route path="/prodotti-gioielleria" element={<ProdottiGioielleria />} />
        <Route path="/prodotti-orologeria" element={<ProdottiOrologeria />} />
        <Route path="/chi-siamo" element={<ChiSiamo />} />
        <Route path="/contattaci" element={<Contattaci />} />
        <Route path="/marchi" element={<Marchi />} />
        <Route path="/lista-marchio" element={<ListaOggettiMarchio />} />
        <Route path="/prodotto-marchio" element={<ProdottiMarchi />} />
      </Routes>
    </Router>
  );
};

export default App;

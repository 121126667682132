import React from "react";
import { Navbar } from "./Navbar";
import { Footer } from "./Footer";


export const Contattaci = () => {
  return (
    <div className="flex flex-col items-center w-full">
      <Navbar />
      
      {/* Immagine sotto la Navbar */}
      <div className="w-[600px] border-[30px] border-white">
        <img
          src="../img/rectangle-16-1@2x.png"
          alt="Sfondo Orologeria"
          className="w-full object-cover"
        />
      </div>

      {/* Sezione contatti */}
      <div className="flex flex-col items-center text-center gap-4 mt-12 w-[80%] max-w-[600px]">
        <p className="text-lg font-elegant text-2xl">
          Se hai bisogno di assistenza, il nostro team di esperti è a tua completa
          disposizione per qualsiasi domanda, consiglio di stile o idea regalo.
          Non esitare a contattarci, siamo qui per te!
        </p>
        <p className="text-lg font-medium font-elegant text-2xl">
          Tel: <a href="tel:+39039388876" className="text-blue-600">+39 039388876</a>
        </p>
        <p className="text-lg font-medium font-elegant text-2xl">
          eMail: <a href="mailto:orologi.strippoli@gmail.com" className="text-blue-600">
            orologi.strippoli@gmail.com
          </a>
        </p>
      </div>

      {/* Sezione Mappa */}
      <div className="mt-12 w-full flex justify-center">
        <iframe
          title="Google Maps"
          src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d89350.26410966784!2d9.200855806441302!3d45.586609441393016!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x4786b9976de504d5%3A0xf5f87dfdfa46896d!2sVia%20Lecco%2C%2018%2C%2020900%20Monza%20MB!3m2!1d45.586543899999995!2d9.2830476!5e0!3m2!1sit!2sit!4v1732875301504!5m2!1sit!2sit"
          width="600"
          height="450"
          style={{ border: 0 }}
          allowFullScreen={true}
          loading="lazy"
        ></iframe>
      </div>
      <Footer />
    </div>
  );
};

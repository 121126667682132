import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { Navbar } from "./Navbar";
import { Footer } from "./Footer";

interface Product {
  id: string;
  category: string;
  name: string;
  material: string;
  price: string;
  description: string;
  image: string;
  marca: string;
}

export const ListaOggettiMarchio = () => {
  const { state } = useLocation();
  const selectedBrand = typeof state === "string" ? state : state?.marca;
  const [products, setProducts] = useState<Product[]>([]);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const navigate = useNavigate();

  const SPREADSHEET_ID = "1P6aNYWLOmysvMxCGomJX3sHD630bIEIbRdrVK0kj42g";
  const YOUR_API_KEY = "AIzaSyDApd2IAji0C8D7cTpv8phMYiqDShinc5c";
  const SHEET_NAME = "Strippoli";

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        setErrorMessage(null);

        const url = `https://sheets.googleapis.com/v4/spreadsheets/${SPREADSHEET_ID}/values/${SHEET_NAME}?key=${YOUR_API_KEY}`;
        const response = await axios.get(url);
        const rows = response.data.values;

        if (!rows || rows.length <= 1) {
          throw new Error("Nessun dato trovato nel foglio.");
        }

        const loadedProducts = rows.slice(1).map((row: string[]) => ({
          id: row[0],
          category: row[1],
          name: row[2],
          material: row[3],
          price: row[4],
          description: row[5],
          image: row[6]?.replace("/view?usp=drive_link", "/preview"),
          marca: row[7],
        }));

        const filteredProducts = loadedProducts.filter(
          (product: { marca: any; }) => product.marca === selectedBrand
        );

        setProducts(filteredProducts);
      } catch (err: any) {
        const errorDetail =
          err.response?.data?.error?.message ||
          err.message ||
          "Errore sconosciuto.";
        setErrorMessage(errorDetail);
      }
    };

    if (selectedBrand) {
      fetchProducts();
    } else {
      setErrorMessage("Marchio non valido.");
    }
  }, [selectedBrand]);

  return (
    <>
      <Navbar />
      <div className="flex flex-col items-center bg-white w-full font-elegant text-2xl py-12 px-6">
        {selectedBrand && (
          <h1 className="text-3xl font-bold mb-6 font-elegant">
            {selectedBrand}
          </h1>
        )}
        {errorMessage ? (
          <p className="text-red-500 text-center font-elegant">
            {errorMessage}
          </p>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 mt-12 w-full font-elegant">
            {products.map((product) => (
              <div
                key={product.id}
                className="flex flex-col items-center border rounded-lg p-4 shadow-md bg-white cursor-pointer font-elegant  border-1 border-green-800"
                onClick={() =>
                  navigate("/prodotto-marchio", { state: product })
                }
              >
                <img
                  src={product.image}
                  alt={product.name}
                  className="h-[200px] w-auto object-cover rounded-lg font-elegant"
                />
                <h3 className="text-lg font-semibold mt-4 text-center font-elegant">
                  {product.name}
                </h3>
                <p className="text-sm text-gray-700 mt-2 text-center font-elegant">
                  {product.material}
                </p>
                <p className="text-lg font-bold mt-2 text-center font-elegant">
                  {product.price}
                </p>
              </div>
            ))}
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

import React, { useState } from "react";

export const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <div className="bg-white w-full font-elegant text-2xl">
      {/* Logo e Hamburger Icon */}
      <div className="flex items-center justify-between px-4 py-4 lg:flex-col lg:items-center">
        {/* Logo */}
        <a href="/" className="lg:mb-4">
          <img
            className="h-[80px] w-auto lg:h-[100px] lg:mx-auto"
            src="/img/Logo2.png"
            alt="Logo Oreficeria"
          />
        </a>
        {/* Burger Icon (visible on mobile) */}
        <button
          className="text-black text-3xl lg:hidden"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          &#9776; {/* Hamburger Icon */}
        </button>
      </div>

      {/* Menu Links */}
      <div
        className={`${
          isMenuOpen ? "block" : "hidden"
        } lg:flex lg:flex-col lg:items-center`}
      >
        <div className="flex flex-col lg:flex-row justify-center items-center gap-4 lg:gap-8">
          <a
            href="/gioielleria"
            className="text-lg font-medium text-black font-elegant text-2xl"
          >
            GIOIELLERIA
          </a>
          <a
            href="/orologeria"
            className="text-lg font-medium text-black font-elegant text-2xl"
          >
            OROLOGERIA
          </a>
          <a
            href="/chi-siamo"
            className="text-lg font-medium text-black font-elegant text-2xl"
          >
            CHI SIAMO
          </a>
          <a
            href="/marchi"
            className="text-lg font-medium text-black font-elegant text-2xl"
          >
            MARCHI
          </a>
          <a
            href="/contattaci"
            className="text-lg font-medium text-black font-elegant text-2xl"
          >
            CONTATTACI
          </a>
        </div>
      </div>
    </div>
  );
};

import React from "react";
import { useLocation } from "react-router-dom";
import { Navbar } from "./Navbar";
import { Footer } from "./Footer";

export const ProdottiGioielleria = () => {
  const { state: product } = useLocation();

  if (!product) {
    return (
      <p className="text-center text-red-500 font-elegant">
        Nessun prodotto selezionato.
      </p>
    );
  }

  return (
    <div className="flex flex-col items-center bg-white w-full overflow-hidden font-elegant">
      <Navbar />
      <div className="flex justify-center mt-12 px-6">
        <div className="flex flex-col lg:flex-row items-start gap-12 w-full max-w-4xl">
          {/* Immagine */}
          <img
            src={product.image}
            alt={product.name}
            className="w-full lg:w-[400px] h-auto lg:h-[600px] object-cover rounded-lg shadow-md border-4 border-gray-400"
          />

          {/* Dettagli */}
          <div className="flex flex-col justify-center lg:justify-start text-center lg:text-left mt-6 lg:mt-0">
            <h1 className="text-4xl font-bold mb-6 text-black">{product.name}</h1>
            <p className="text-2xl text-gray-800 mb-4">
              <strong>Marca:</strong> {product.marca}
            </p>
            <p className="text-xl text-gray-700 mb-4">
              <strong>Materiale:</strong> {product.material}
            </p>
            <p className="text-3xl font-bold text-gray-900 mb-6">{product.price}</p>
            <p className="text-lg text-gray-600">{product.description}</p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
